document.addEventListener('DOMContentLoaded', function () {
  const contents = document.querySelectorAll('.contact-cta-block__inner');

  // Fonction pour convertir les numéros de téléphone et les e-mails
  function convertLinks(text) {
    // Regex pour les numéros de téléphone incluant les formats US et Canada
    const phoneRegex =
      /(\+?1[-.\s]?(\(?\d{3}\)?|[2-9]\d{2})[-.\s]?\d{3}[-.\s]?\d{4}|(\d{3}\s\d{3}-\d{4}))/g;
    // Regex pour les adresses e-mail
    const emailRegex = /([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/g;

    text = text.replace(phoneRegex, function (match) {
      const phoneLinkRegex = new RegExp(
        `<a[^>]*href=["']tel:${match.replace(
          /[-.\s]/g,
          ''
        )}["'][^>]*>[^<]*<\/a>`
      );
      return phoneLinkRegex.test(text)
        ? match
        : `<a href="tel:${match.replace(/[-.\s]/g, '')}">${match}</a>`;
    });

    text = text.replace(emailRegex, function (match) {
      const emailLinkRegex = new RegExp(
        `<a[^>]*href=["']mailto:${match}["'][^>]*>[^<]*<\/a>`
      );
      return emailLinkRegex.test(text)
        ? match
        : `<a href="mailto:${match}">${match}</a>`;
    });

    return text;
  }

  contents.forEach(function (content) {
    if (content) {
      // Vérifiez si l'élément contient un lien avec la classe .wp-element-button
      const hasWpElementButton = content.querySelector('a.wp-element-button');
      if (!hasWpElementButton) {
        content.innerHTML = convertLinks(content.innerHTML);
      }
    }
  });
});
